// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7p4dJZtbx{overflow:visible !important}._7p4dJZtbx:focus{outline:none}", "",{"version":3,"sources":["webpack://client/shared/list/list.scss"],"names":[],"mappings":"AAAA,YACE,2BAAA,CACA,kBACE,YAAA","sourcesContent":[".rv-list {\n  overflow: visible !important;\n  &:focus {\n    outline: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rv-list": "_7p4dJZtbx",
	"rvList": "_7p4dJZtbx"
};
export default ___CSS_LOADER_EXPORT___;
